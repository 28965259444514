<script>
import {
  GlLink,
  GlDisclosureDropdown,
  GlDisclosureDropdownGroup,
  GlDisclosureDropdownItem,
} from '@gitlab/ui';
import Tracking from '~/javascripts/tracking';
import {
  TRACKING_ACTIONS,
  TRACKING_LABELS,
  TRACKING_CATEGORIES,
} from '~/javascripts/tracking/constants';
import { SUBMIT_FEEDBACK_URL, LICENSING_FAQ_URL, CONTACT_SUPPORT_URL } from '../constants';

export default {
  name: 'NavBarItems',
  components: {
    GlLink,
    GlDisclosureDropdown,
    GlDisclosureDropdownGroup,
    GlDisclosureDropdownItem,
  },
  inject: {
    isSignedIn: 'isSignedIn',
    hasSubscriptions: 'hasSubscriptions',
    customerFullName: {
      default: '',
    },
    hasBillingAccount: {
      default: false,
    },
    contactUsUrl: 'contactUsUrl',
    subscriptionsPath: 'subscriptionsPath',
    invoicesPath: 'invoicesPath',
    accountDetailsPath: 'accountDetailsPath',
    paymentMethodsPath: 'paymentMethodsPath',
    signOutPath: 'signOutPath',
    signInPath: 'signInPath',
  },
  created() {
    this.navClasses = {
      'navbar-nav': true,
      'gl-ml-auto': true,
      'gl-flex-row': this.hasBillingAccount, // Always display the menu inline for users with a billing account
    };
    this.navLinkClasses = {
      // GlLink and GlButton[variant="link"] overrides
      '!gl-justify-start': true,
      'gl-grow': true,
      '!gl-text-sm': true,
      'gl-font-bold': true,
      '!gl-text-brand-gray-03': true,
      '!gl-no-underline': true,
      'active:!gl-text-brand-gray-05': true,
      'focus:!gl-text-brand-gray-05': true,
      'hover:!gl-text-brand-gray-05': true,
      '!gl-py-3': true,
      // Padding x is only needed when displayed in the menu bar, i.e. not in the toggle menu
      // When user has a billing account we never display the items in a toggle menu
      'lg:!gl-px-3': !this.hasBillingAccount,
      '!gl-px-3': this.hasBillingAccount,
    };
  },
  methods: {
    trackEvent(label) {
      const tracking = new Tracking();
      tracking.event(TRACKING_CATEGORIES.webstore, TRACKING_ACTIONS.clickLink, { label });
    },
  },
  SUBMIT_FEEDBACK_URL,
  LICENSING_FAQ_URL,
  CONTACT_SUPPORT_URL,
  TRACKING_LABELS,
  navLinkAttributes: {
    role: 'menuitem',
  },
  navItemClasses: {
    'gl-flex': true,
  },
  dropdownClasses: {
    'gl-grow': true,
  },
};
</script>

<template>
  <ul :class="navClasses" role="menubar">
    <template v-if="isSignedIn">
      <li :class="$options.navItemClasses">
        <gl-link
          v-if="!hasBillingAccount"
          :href="subscriptionsPath"
          :class="navLinkClasses"
          v-bind="$options.navLinkAttributes"
          >Manage purchases</gl-link
        >
      </li>
      <li :class="$options.navItemClasses">
        <gl-link
          v-if="!hasBillingAccount"
          :href="invoicesPath"
          :class="navLinkClasses"
          v-bind="$options.navLinkAttributes"
          >View invoices</gl-link
        >
      </li>
      <li :class="$options.navItemClasses">
        <gl-disclosure-dropdown
          data-testid="info"
          icon="question"
          variant="link"
          :class="$options.dropdownClasses"
          :toggle-class="navLinkClasses"
          @shown="trackEvent($options.TRACKING_LABELS.headerInfo)"
          @hidden="trackEvent($options.TRACKING_LABELS.headerInfo)"
        >
          <gl-disclosure-dropdown-group>
            <gl-disclosure-dropdown-item
              :item="{
                href: $options.LICENSING_FAQ_URL,
                text: 'Frequently asked questions',
                extraAttrs: $options.navLinkAttributes,
              }"
            />
            <gl-disclosure-dropdown-item
              :item="{
                href: $options.CONTACT_SUPPORT_URL,
                text: 'Support',
                extraAttrs: $options.navLinkAttributes,
              }"
            />
            <gl-disclosure-dropdown-item
              :item="{
                href: contactUsUrl,
                text: 'Contact us',
                extraAttrs: $options.navLinkAttributes,
              }"
            />
          </gl-disclosure-dropdown-group>

          <gl-disclosure-dropdown-group bordered>
            <gl-disclosure-dropdown-item
              :item="{
                href: $options.SUBMIT_FEEDBACK_URL,
                text: 'Submit feedback',
                extraAttrs: $options.navLinkAttributes,
              }"
            />
          </gl-disclosure-dropdown-group>
        </gl-disclosure-dropdown>
      </li>
      <li :class="$options.navItemClasses">
        <gl-disclosure-dropdown
          data-testid="profile"
          toggle-text="My profile"
          variant="link"
          :class="$options.dropdownClasses"
          :toggle-class="navLinkClasses"
          @shown="trackEvent($options.TRACKING_LABELS.headerProfile)"
          @hidden="trackEvent($options.TRACKING_LABELS.headerProfile)"
        >
          <gl-disclosure-dropdown-group>
            <template #group-label>
              {{ customerFullName }}
            </template>

            <gl-disclosure-dropdown-item
              v-if="hasBillingAccount"
              :item="{
                href: '/',
                text: 'My billing account',
                extraAttrs: $options.navLinkAttributes,
              }"
            />
            <gl-disclosure-dropdown-item
              :item="{
                href: accountDetailsPath,
                text: 'Profile settings',
                extraAttrs: $options.navLinkAttributes,
              }"
            />
            <gl-disclosure-dropdown-item
              v-if="!hasBillingAccount && hasSubscriptions"
              :item="{
                href: paymentMethodsPath,
                text: 'Payment methods',
                extraAttrs: $options.navLinkAttributes,
              }"
            />
          </gl-disclosure-dropdown-group>
          <gl-disclosure-dropdown-group bordered>
            <gl-disclosure-dropdown-item
              :item="{
                href: signOutPath,
                text: 'Sign out',
                extraAttrs: { ...$options.navLinkAttributes, 'data-method': 'delete' },
              }"
            />
          </gl-disclosure-dropdown-group>
        </gl-disclosure-dropdown>
      </li>
    </template>
    <template v-else>
      <li :class="$options.navItemClasses">
        <gl-link
          data-testid="navbar-sign-in"
          :href="signInPath"
          :class="navLinkClasses"
          v-bind="$options.navLinkAttributes"
          >Sign in</gl-link
        >
      </li>
    </template>
  </ul>
</template>
