export const EMPLOYEES_COUNT = [
  { value: '1-99', text: '1-99' },
  { value: '100-499', text: '100-499' },
  { value: '500-1,999', text: '500-1,999' },
  { value: '2,000-9,999', text: '2,000-9,999' },
  { value: '10,000+', text: '10,000+' },
];

export const LEGACY_ACCOUNT_MESSAGE =
  'If you have a legacy account, you can still access it for now by %{legacySignInLinkStart}signing in with your email%{legacySignInLinkEnd}. If your Customers Portal account is not linked to a GitLab.com account, you should %{linkGitLabAccountLinkStart}create and link a GitLab.com account%{linkGitLabAccountLinkEnd}.';

export const LINK_GITLAB_ACCOUNT_DOCS_URL =
  'https://docs.gitlab.com/ee/subscriptions/customers_portal.html#change-the-linked-account';
export const COOKIE_POLICY = 'https://about.gitlab.com/privacy/cookies/';
export const PRIVACY_STATEMENT = 'https://about.gitlab.com/privacy';
