<script>
import GITLAB_LOGO_SVG from '@gitlab/svgs/dist/illustrations/gitlab_logo.svg';
import { GlButton } from '@gitlab/ui';
import { MountingPortal } from 'portal-vue';
import csrf from '~/javascripts/lib/csrf';
import FooterApp from './footer_app.vue';

export default {
  name: 'SignInApp',
  components: {
    GlButton,
    FooterApp,
    MountingPortal,
  },
  props: {
    ssoUrl: {
      type: String,
      required: true,
    },
    gitlabSignUpUrl: {
      type: String,
      required: false,
      default: null,
    },
    legacyAccountSignInUrl: {
      type: String,
      required: true,
    },
    productToPurchase: {
      type: String,
      required: false,
      default: null,
    },
    showRegisterOnGitlabCta: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      csrfToken: csrf.token,
    };
  },
  GITLAB_LOGO_SVG,
  computed: {
    title() {
      return this.productToPurchase ? `Buy ${this.productToPurchase}` : 'Sign in';
    },
  },
};
</script>

<template>
  <div class="gl-m-auto gl-my-8 gl-text-center">
    <div class="gl-max-w-48 gl-m-auto">
      <h2 class="gl-font-bold" data-testid="title">{{ title }}</h2>
      <p v-if="productToPurchase" class="gl-font-bold gl-mt-5">
        Please sign in to complete your purchase
      </p>
      <p class="gl-mt-5">
        The Customers Portal is a secure site for managing GitLab subscriptions, billing contacts,
        payments, and license details, accessed with your GitLab.com account.
      </p>
    </div>
    <form method="post" :action="ssoUrl">
      <input name="utf8" type="hidden" value="✓" />
      <input type="hidden" name="authenticity_token" :value="csrfToken" />

      <div class="gl-flex gl-flex-wrap gl-gap-3 gl-justify-center gl-mt-8">
        <gl-button type="submit" data-testid="sign-in-with-gitlab-button">
          <span>
            <img class="gl-w-5" :src="$options.GITLAB_LOGO_SVG" alt="GitLab Logo" />
            Sign in with GitLab.com account
          </span>
        </gl-button>

        <gl-button
          v-if="showRegisterOnGitlabCta"
          target="_blank"
          category="primary"
          :href="gitlabSignUpUrl"
          data-testid="register-on-gitlab-button"
        >
          Register new GitLab.com account
        </gl-button>
      </div>
    </form>
    <mounting-portal mount-to="#js-footer" slim>
      <footer-app :legacy-account-sign-in-url="legacyAccountSignInUrl" />
    </mounting-portal>
  </div>
</template>
