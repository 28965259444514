<script>
import Tracking from '~/javascripts/tracking';
import { TRACKING_ACTIONS, TRACKING_CATEGORIES } from '~/javascripts/tracking/constants';

export default {
  name: 'SidebarItem',
  components: {},
  i18n: {},
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
    trackingLabel: {
      type: String,
      required: true,
    },
  },
  computed: {
    linkClasses() {
      return [
        'gl-px-4 gl-py-3 gl-mb-1 gl-relative gl-flex gl-rounded-base gl-leading-normal !gl-text-gray-900 hover:gl-bg-alpha-dark-8 active:gl-bg-alpha-dark-8 !gl-no-underline focus:gl-focus',
        this.isActive ? 'gl-bg-alpha-dark-8' : '',
      ];
    },
  },
  methods: {
    trackEvent() {
      const tracking = new Tracking();
      tracking.event(TRACKING_CATEGORIES.webstore, TRACKING_ACTIONS.clickSideMenu, {
        label: this.trackingLabel,
      });
    },
  },
};
</script>

<template>
  <li>
    <a :class="linkClasses" :href="path" @click="trackEvent">
      <slot></slot>
    </a>
  </li>
</template>
