<script>
import { GlCard, GlLink, GlSprintf } from '@gitlab/ui';
import {
  COOKIE_POLICY,
  LEGACY_ACCOUNT_MESSAGE,
  LINK_GITLAB_ACCOUNT_DOCS_URL,
  PRIVACY_STATEMENT,
} from '../sessions_constants';

export default {
  name: 'FooterApp',
  components: {
    GlCard,
    GlLink,
    GlSprintf,
  },
  props: {
    legacyAccountSignInUrl: {
      type: String,
      required: true,
    },
  },
  LEGACY_ACCOUNT_MESSAGE,
  LINK_GITLAB_ACCOUNT_DOCS_URL,
  COOKIE_POLICY,
  PRIVACY_STATEMENT,
};
</script>

<template>
  <div class="gl-flex gl-flex-col gl-items-center">
    <gl-card class="gl-max-w-80 gl-text-center gl-mx-7">
      <p class="gl-text-gray-600" data-testid="legacy-account-message">
        <gl-sprintf :message="$options.LEGACY_ACCOUNT_MESSAGE">
          <template #legacySignInLink="{ content }">
            <gl-link :href="legacyAccountSignInUrl" data-testid="legacy-sign-in-url">{{
              content
            }}</gl-link>
          </template>
          <template #linkGitLabAccountLink="{ content }">
            <gl-link
              :href="$options.LINK_GITLAB_ACCOUNT_DOCS_URL"
              data-testid="link-gitlab-account-link"
              >{{ content }}</gl-link
            >
          </template>
        </gl-sprintf>
      </p>
    </gl-card>
    <div class="gl-my-7">
      <gl-link :href="$options.COOKIE_POLICY" data-testid="cookie-policy">Cookie Policy</gl-link>
      <span
        class="gl-border-gray-100 gl-border-0 gl-border-r-1 gl-border-solid gl-ml-2 gl-mr-3"
      ></span>
      <gl-link class="ot-sdk-show-settings" href="#" data-testid="cookie-preferences"
        >Cookie Preferences</gl-link
      >
      <span
        class="gl-border-gray-100 gl-border-0 gl-border-r-1 gl-border-solid gl-ml-2 gl-mr-3"
      ></span>
      <gl-link :href="$options.PRIVACY_STATEMENT" data-testid="privacy-statement"
        >Privacy Statement</gl-link
      >
    </div>
  </div>
</template>
