import Vue from 'vue';
import SignInApp from './components/sign_in_app.vue';

export default () => {
  const el = document.getElementById('js-sso');

  if (!el) return false;

  const {
    ssoUrl,
    gitlabSignUpUrl,
    legacyAccountSignInUrl,
    productToPurchase,
    showRegisterOnGitlabCta,
  } = el.dataset;

  return new Vue({
    el,
    render: (createElement) =>
      createElement(SignInApp, {
        props: {
          ssoUrl,
          gitlabSignUpUrl,
          legacyAccountSignInUrl,
          productToPurchase,
          showRegisterOnGitlabCta,
        },
      }),
  });
};
