<script>
import * as Sentry from '@sentry/browser';
import getBillingAccountQuery from '~/javascripts/billing_account/graphql/queries/get_billing_account.query.graphql';
import SidebarToggleButton from './sidebar_toggle_button.vue';

export default {
  name: 'SidebarHeaderApp',
  components: { SidebarToggleButton },
  inject: ['zuoraAccountNumber'],
  data() {
    return {
      billingAccount: {},
    };
  },
  apollo: {
    billingAccount: {
      query: getBillingAccountQuery,
      variables() {
        return {
          zuoraAccountNumber: this.zuoraAccountNumber,
        };
      },
      skip() {
        return !this.zuoraAccountNumber;
      },
      update(data) {
        return data.billingAccount || {};
      },
      error(error) {
        this.handleError(error);
      },
    },
  },
  methods: {
    handleError(error) {
      Sentry.captureException(error);
    },
  },
};
</script>

<template>
  <div
    data-testid="sidebar-header"
    class="sidebar-header container-fluid gl-max-w-container-xl gl-px-5"
  >
    <sidebar-toggle-button
      class="-gl-ml-3 gl-mr-2"
      button-text="Show sidebar"
      button-icon="sidebar"
    />
    <label class="gl-mb-0" data-testid="sidebar-header-label">{{
      billingAccount.zuoraAccountName
    }}</label>
  </div>
</template>
