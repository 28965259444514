<script>
import GITLAB_LOGO_WITH_TITLE_SVG from '@gitlab/svgs/dist/illustrations/gitlab-lockup.svg';
import GITLAB_LOGO_SVG from '@gitlab/svgs/dist/illustrations/gitlab_logo.svg';
import { GlCollapse, GlCollapseToggleDirective } from '@gitlab/ui';
import Tracking from '~/javascripts/tracking';
import {
  TRACKING_ACTIONS,
  TRACKING_LABELS,
  TRACKING_CATEGORIES,
} from '~/javascripts/tracking/constants';
import NavBarItems from './nav_bar_items.vue';

export default {
  name: 'NavBarApp',
  components: {
    GlCollapse,
    NavBarItems,
  },
  directives: {
    GlCollapseToggle: GlCollapseToggleDirective,
  },
  inject: {
    isSignedIn: 'isSignedIn',
    hasSubscriptions: 'hasSubscriptions',
    customerFullName: {
      default: '',
    },
    hasBillingAccount: {
      default: false,
    },
    contactUsUrl: 'contactUsUrl',
    subscriptionsPath: 'subscriptionsPath',
    invoicesPath: 'invoicesPath',
    accountDetailsPath: 'accountDetailsPath',
    paymentMethodsPath: 'paymentMethodsPath',
    signOutPath: 'signOutPath',
    signInPath: 'signInPath',
    displayNavigationItems: {
      default: true,
    },
  },
  created() {
    this.containerClasses = this.hasBillingAccount
      ? 'container-fluid gl-max-w-container-xl gl-px-5'
      : 'container';
  },
  methods: {
    trackEvent(label) {
      const tracking = new Tracking();
      tracking.event(TRACKING_CATEGORIES.webstore, TRACKING_ACTIONS.clickLink, { label });
    },
  },
  GITLAB_LOGO_WITH_TITLE_SVG,
  GITLAB_LOGO_SVG,
  TRACKING_LABELS,
};
</script>

<template>
  <nav
    class="navbar navbar-expand-lg navbar-gitlab navbar-light"
    aria-labelledby="primary-navigation"
  >
    <div data-testid="navbar-container" :class="containerClasses">
      <a
        href="/"
        data-testid="logo"
        class="navbar-brand gl-flex gl-items-center"
        @click="trackEvent($options.TRACKING_LABELS.headerLogo)"
      >
        <div class="gl-border-r gl-hidden gl-border-gray-300 gl-pr-3 sm:gl-inline">
          <img :src="$options.GITLAB_LOGO_WITH_TITLE_SVG" class="gl-h-6" alt="" />
          <span class="gl-sr-only">GitLab</span>
        </div>
        <div class="gl-mr-3 sm:gl-hidden">
          <img :src="$options.GITLAB_LOGO_SVG" class="gl-h-5" alt="" />
          <span class="gl-sr-only">GitLab </span>
        </div>
        <div
          class="
            sm:gl-ml-3
            gl-text-brand-gray-03
            active:!gl-text-brand-gray-05
            focus:!gl-text-brand-gray-05
            hover:!gl-text-brand-gray-05
          "
        >
          Customers Portal
        </div>
      </a>

      <template v-if="displayNavigationItems">
        <button
          v-if="!hasBillingAccount"
          v-gl-collapse-toggle.primary-navigation
          class="navbar-toggler"
          type="button"
          aria-controls="primary-navigation"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <gl-collapse v-if="!hasBillingAccount" id="primary-navigation" class="navbar-collapse">
          <nav-bar-items />
        </gl-collapse>
        <nav-bar-items v-else />
      </template>
    </div>
  </nav>
</template>
