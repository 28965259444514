var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{class:_vm.navClasses,attrs:{"role":"menubar"}},[(_vm.isSignedIn)?[_c('li',{class:_vm.$options.navItemClasses},[(!_vm.hasBillingAccount)?_c('gl-link',_vm._b({class:_vm.navLinkClasses,attrs:{"href":_vm.subscriptionsPath}},'gl-link',_vm.$options.navLinkAttributes,false),[_vm._v("Manage purchases")]):_vm._e()],1),_vm._v(" "),_c('li',{class:_vm.$options.navItemClasses},[(!_vm.hasBillingAccount)?_c('gl-link',_vm._b({class:_vm.navLinkClasses,attrs:{"href":_vm.invoicesPath}},'gl-link',_vm.$options.navLinkAttributes,false),[_vm._v("View invoices")]):_vm._e()],1),_vm._v(" "),_c('li',{class:_vm.$options.navItemClasses},[_c('gl-disclosure-dropdown',{class:_vm.$options.dropdownClasses,attrs:{"data-testid":"info","icon":"question","variant":"link","toggle-class":_vm.navLinkClasses},on:{"shown":function($event){return _vm.trackEvent(_vm.$options.TRACKING_LABELS.headerInfo)},"hidden":function($event){return _vm.trackEvent(_vm.$options.TRACKING_LABELS.headerInfo)}}},[_c('gl-disclosure-dropdown-group',[_c('gl-disclosure-dropdown-item',{attrs:{"item":{
              href: _vm.$options.LICENSING_FAQ_URL,
              text: 'Frequently asked questions',
              extraAttrs: _vm.$options.navLinkAttributes,
            }}}),_vm._v(" "),_c('gl-disclosure-dropdown-item',{attrs:{"item":{
              href: _vm.$options.CONTACT_SUPPORT_URL,
              text: 'Support',
              extraAttrs: _vm.$options.navLinkAttributes,
            }}}),_vm._v(" "),_c('gl-disclosure-dropdown-item',{attrs:{"item":{
              href: _vm.contactUsUrl,
              text: 'Contact us',
              extraAttrs: _vm.$options.navLinkAttributes,
            }}})],1),_vm._v(" "),_c('gl-disclosure-dropdown-group',{attrs:{"bordered":""}},[_c('gl-disclosure-dropdown-item',{attrs:{"item":{
              href: _vm.$options.SUBMIT_FEEDBACK_URL,
              text: 'Submit feedback',
              extraAttrs: _vm.$options.navLinkAttributes,
            }}})],1)],1)],1),_vm._v(" "),_c('li',{class:_vm.$options.navItemClasses},[_c('gl-disclosure-dropdown',{class:_vm.$options.dropdownClasses,attrs:{"data-testid":"profile","toggle-text":"My profile","variant":"link","toggle-class":_vm.navLinkClasses},on:{"shown":function($event){return _vm.trackEvent(_vm.$options.TRACKING_LABELS.headerProfile)},"hidden":function($event){return _vm.trackEvent(_vm.$options.TRACKING_LABELS.headerProfile)}}},[_c('gl-disclosure-dropdown-group',{scopedSlots:_vm._u([{key:"group-label",fn:function(){return [_vm._v("\n            "+_vm._s(_vm.customerFullName)+"\n          ")]},proxy:true}],null,false,2928904404)},[_vm._v(" "),(_vm.hasBillingAccount)?_c('gl-disclosure-dropdown-item',{attrs:{"item":{
              href: '/',
              text: 'My billing account',
              extraAttrs: _vm.$options.navLinkAttributes,
            }}}):_vm._e(),_vm._v(" "),_c('gl-disclosure-dropdown-item',{attrs:{"item":{
              href: _vm.accountDetailsPath,
              text: 'Profile settings',
              extraAttrs: _vm.$options.navLinkAttributes,
            }}}),_vm._v(" "),(!_vm.hasBillingAccount && _vm.hasSubscriptions)?_c('gl-disclosure-dropdown-item',{attrs:{"item":{
              href: _vm.paymentMethodsPath,
              text: 'Payment methods',
              extraAttrs: _vm.$options.navLinkAttributes,
            }}}):_vm._e()],1),_vm._v(" "),_c('gl-disclosure-dropdown-group',{attrs:{"bordered":""}},[_c('gl-disclosure-dropdown-item',{attrs:{"item":{
              href: _vm.signOutPath,
              text: 'Sign out',
              extraAttrs: { ..._vm.$options.navLinkAttributes, 'data-method': 'delete' },
            }}})],1)],1)],1)]:[_c('li',{class:_vm.$options.navItemClasses},[_c('gl-link',_vm._b({class:_vm.navLinkClasses,attrs:{"data-testid":"navbar-sign-in","href":_vm.signInPath}},'gl-link',_vm.$options.navLinkAttributes,false),[_vm._v("Sign in")])],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }